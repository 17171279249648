import { StyledBg } from '../style/HomePageTimeStyles'
import { useContext } from 'react'
import UserStorage from '../storage/UserStorage/UserStorage'

export const HomePageTime = () => {
  const UserStore = useContext(UserStorage)
  const isAuth = !!UserStore?.accessToken

  return (
    <StyledBg>
      <img
        src="./images/Time/stone-top.png"
        srcSet="./images/Time/stone-top.png"
        alt="stone-top"
        className="stone-top"
      />
      <header className="header">
        <a href="/">
          <img src="./images/Time/logo.svg" alt="logo" className="logo" width="85px" />
        </a>
        <nav className="header-nav">
          {/* <a href=""><img src="./images/Time/lang-toggle.svg" alt="lang-toggle" className="lang-toggle"></a> */}
          <div className="header-nav desktop" style={{ gap: '30px' }}>
            {!isAuth && (
              <>
                <a href="/login" className="nav-link button-dark" rel="noreferrer">
                  Log in
                </a>
                <a href="/register" className="nav-link button-light" rel="noreferrer">
                  Sign up
                </a>
              </>
            )}
            {isAuth && (
              <a href="/dashboard" className="nav-link button-dark" rel="noreferrer">
                Open Dashboard
              </a>
            )}
          </div>
          <a href="/register" className="menu mobile" rel="noreferrer">
            <img src="./images/Time/menu-btn.svg" alt="menu" />
          </a>
        </nav>
      </header>
      <div className="content">
        <div className="section-wrapper">
          <img
            src="./images/Time/stone-right.png"
            srcSet="./images/Time/stone-right-small.png 768w, ./images/Time/stone-right.png"
            alt="stone-right"
            className="stone-right"
          />
          <img
            src="./images/Time/stone-left.png"
            srcSet="./images/Time/stone-left-small.png 768w, ./images/Time/stone-left.png"
            alt="stone-left"
            className="stone-left"
          />
          <section className="section section-top">
            <h1 className="title title-top">
              Time<span style={{ color: '#F2764F' }}>.</span>Investments
            </h1>
            <h2 className="subtitle title-top">
              Easy and safe <br /> investing for everyone
            </h2>
            <img
              src="./images/Time/phone-main-small.png"
              srcSet="./images/Time/phone-main-small.png 768w, ./images/Time/phone-main.png"
              alt="phone-main"
              className="phones-planet"
            />
            <p className="large-text large-text-top">
              Access to exclusive investment opportunities in pre-IPO, venture capital, funds and
              cryptocurrencies for investors of all levels
            </p>
            <a
              href="/register"
              className="nav-link button-large mobile self-center full-width"
              rel="noreferrer"
            >
              Sign up
            </a>
            <div className="header-nav desktop" style={{ gap: '30px', marginTop: '30px' }}>
              {!isAuth && (
                <>
                  <a href="/login" className="nav-link button-dark button-large" rel="noreferrer">
                    Log in
                  </a>
                  <a href="/register" className="nav-link button-large" rel="noreferrer">
                    Sign up
                  </a>
                </>
              )}
              {isAuth && (
                <a href="/dashboard" className="nav-link button-dark button-large" rel="noreferrer">
                  Open Dashboard
                </a>
              )}
            </div>
          </section>
          <section className="section section-history">
            <div className="history-block">
              <h2 className="title-history">2015y</h2>
              <p className="medium-text large-text-history">
                Work experience <br />
                in the crypto market
              </p>
            </div>
            <div className="history-block">
              <h2 className="title-history">1000+</h2>
              <p className="medium-text large-text-history">Successful transactions</p>
            </div>
            <div className="history-block">
              <h2 className="title-history">350%</h2>
              <p className="medium-text large-text-history">Average yield</p>
            </div>
            <div className="history-block">
              <h2 className="title-history">15+</h2>
              <p className="medium-text large-text-history">Areas of investment</p>
            </div>
          </section>
        </div>

        <div className="section section-work-head">
          <h2 className="title title-work">Get started in 30 seconds!</h2>
          <p className="large-text">Our simple way to start investing</p>
        </div>
        <section className="section section-work">
          <div className="work-block">
            <div>
              <nav className="mini-link">1</nav>
              <h2 className="title work-block-title">Create an account for full access</h2>
              <p className="large-text large-text-work">
                Register and confirm your account in 2 minutes. Then you will see all the investment
                destinations.
              </p>
            </div>
            <img src="./images/Time/sign-up-phone.png" alt="sign-up-phone" className="work-phone" />
          </div>
          <div className="work-block">
            <div>
              <nav className="mini-link">2</nav>
              <h2 className="title work-block-title">Invest at a pace that suits you</h2>
              <p className="large-text large-text-work">
                Explore investment opportunities online. Invest in just 2 clicks in something you
                love, starting at just $10 dollars
              </p>
            </div>
            <img src="./images/Time/invest-phone.png" alt="invest-phone" className="work-phone" />
          </div>
          <div className="work-block">
            <div>
              <nav className="mini-link">3</nav>
              <h2 className="title work-block-title">Manage your investments online</h2>
              <p className="large-text large-text-work">
                Track your results in-app with an automatic and easy-to-use dashboard.
              </p>
            </div>

            <img src="./images/Time/profit-phone.png" alt="profit-phone" className="work-phone" />
          </div>
        </section>

        <div className="section-wrapper">
          <img
            src="./images/Time/knot-right.png"
            srcSet="./images/Time/knot-right-small.png 768w, ./images/Time/knot-right.png"
            alt="knot-right"
            className="knot-right"
          />
          <img
            src="./images/Time/knot-left.png"
            srcSet="./images/Time/knot-left-small.png 768w, ./images/Time/knot-left.png"
            alt="knot-left"
            className="knot-left"
          />
          <section className="section section-venture venture">
            <h2 className="title title-venture">
              Time.Investments is an innovative financial platform that connects investors and
              projects.
            </h2>
            <div className="card card-venture">
              <p className="large-text large-text-venture">
                Our mission is to make the nerve-wracking and complicated process of investing as
                easy and understandable as possible for everyone.
              </p>
              <p className="large-text large-text-venture">
                We will do our best so that you are not limited in the choice of investment
                instruments you want to use.
              </p>
            </div>
          </section>
        </div>

        <section className="section section-invest">
          <div className="section section-work-head">
            <h2 className="title title-work">Your Investments Start Here</h2>
            <p className="large-text">
              Time.Investments was created to empower everyone with the opportunity to invest and
              build wealth through smart investments
            </p>
          </div>
          <div className="card card-invest">
            <div className="card-invest-left-text">
              <h2 className="title title-invest">
                Easy start into the <br /> world of digital assets
              </h2>
              <p className="large-text large-text-invest">
                Invest in a $1.3 trillion market capitalization and +48% annual growth through a
                dividend yielding crypto fund
              </p>
              <a className="nav-link nav-link-invest  button-light" href="/marketplace">
                <span>Invest in Crypto</span>
                <img src="./images/Time/link-arrow.svg" alt="link-arrow" />
              </a>
            </div>
            <img
              src="./images/Time/assets-card.png"
              srcSet="./images/Time/assets-card-small.png 768w, ./images/Time/assets-card.png"
              alt="assets-card"
              className="card-invest-right-img"
            />
          </div>
          <div className="card card-invest even-card">
            <img
              src="./images/Time/fonds-card.png"
              srcSet="./images/Time/fonds-card-small.png 768w, ./images/Time/fonds-card.png"
              alt="fonds-card"
              className="card-invest-left-img"
            />
            <div className="card-invest-right-text">
              <h2 className="title title-invest">
                Funds are your source <br /> of regular income
              </h2>
              <p className="large-text large-text-invest">
                Receive dividends at bi-weekly intervals by investing in commodities, real estate,
                stocks and digital currencies funds.
              </p>
              <a className="nav-link nav-link-invest button-light" href="/marketplace">
                <span>Choose a Fund</span>
                <img src="./images/Time/link-arrow.svg" alt="link-arrow" />
              </a>
            </div>
          </div>
          <div className="card card-invest">
            <div className="card-invest-left-text">
              <h2 className="title title-invest">
                Venture deals and Pre-IPOs <br /> available to everyone
              </h2>
              <p className="large-text large-text-invest">
                Invest in pre-screened early and late stage private companies. Some are available
                from as little as $100.
              </p>
              <a className="nav-link nav-link-invest  button-light" href="/marketplace">
                <span>Choose a Company</span>
                <img src="./images/Time/link-arrow.svg" alt="link-arrow" />
              </a>
            </div>
            <img
              src="./images/Time/venture-card.png"
              srcSet="./images/Time/venture-card-small.png 768w, ./images/Time/venture-card.png"
              alt="venture-card"
              className="card-invest-right-img"
            />
          </div>

          <div className="card card-invest even-card">
            <img
              src="./images/Time/energy-card.png"
              srcSet="./images/Time/energy-card-small.png 768w, ./images/Time/energy-card.png"
              alt="fonds-card"
              className="card-invest-left-img"
            />
            <div className="card-invest-right-text">
              <h2 className="title title-invest">Investments in energy resources</h2>
              <p className="large-text large-text-invest">
                This direction is the most interesting of all the fundamental directions, as energy
                consumption is increasing every year.
              </p>
              <a className="nav-link nav-link-invest  button-light" href="/marketplace">
                <span>Explore Further</span>
                <img src="./images/Time/link-arrow.svg" alt="link-arrow" />
              </a>
            </div>
          </div>
        </section>

        <div className="section section-work-head">
          <h2 className="title title-work">Investment opportunities</h2>
          <p className="large-text">
            Drawing on our investment experience since 2015, we leverage our knowledge and
            connections to uncover deals with the highest investment potential for you.
          </p>
        </div>

        <section className="section section-caption">
          <div className="caption-block">
            <img
              src="./images/Time/caption-card-1.png"
              srcSet="./images/Time/caption-card-1-small.png 480w, ./images/Time/caption-card-1.png"
              alt="caption-1"
              className="caption-img"
            />
            <h2 className="title caption-title">
              <img src="./images/Time/logo-small.svg" alt="logo" /> Investments in startups
            </h2>
            <div className="tags-wrapper">
              <div className="tag">TON</div>
              <div className="tag">Presale</div>
              <div className="tag">Seed investments</div>
            </div>
            <p className="small-text caption-text">
              <span>Freq of accuruals</span> <span>Term</span>{' '}
            </p>
            <p className="medium-text caption-text bold">
              <span>Once</span> <span>Flexible</span>{' '}
            </p>
            <br />
            <div className="caption-text">
              <div>
                <div> APR</div>
                <div className="medium-text bold">100%-300%</div>
              </div>
              <a href="/marketplace" className="nav-link button-light" rel="noreferrer">
                Invest
              </a>
            </div>
          </div>

          <div className="caption-block">
            <img
              src="./images/Time/caption-card-2.png"
              srcSet="./images/Time/caption-card-2-small.png 480w, ./images/Time/caption-card-2.png"
              alt="caption-2"
              className="caption-img"
            />
            <h2 className="title caption-title">
              <img src="./images/Time/logo-small.svg" alt="logo" />
              SpaceX{' '}
            </h2>
            <div className="tags-wrapper">
              <div className="tag">PRE-IPO</div>
              <div className="tag">Low risk</div>
              <div className="tag">TOP</div>
            </div>
            <p className="small-text caption-text">
              <span>Freq of accuruals</span> <span>Term</span>{' '}
            </p>
            <p className="medium-text caption-text bold">
              <span>Once</span> <span>Fixed</span>{' '}
            </p>
            <br />
            <div className="caption-text">
              <div>
                <div> APR</div>
                <div className="medium-text bold">0%-500%</div>
              </div>
              <a href="/marketplace" className="nav-link button-light" rel="noreferrer">
                Invest
              </a>
            </div>
          </div>

          <div className="caption-block">
            <img
              src="./images/Time/caption-card-3.png"
              srcSet="./images/Time/caption-card-3-small.png 480w, ./images/Time/caption-card-3.png"
              alt="caption-3"
              className="caption-img"
            />
            <h2 className="title caption-title">
              <img src="./images/Time/logo-small.svg" alt="logo" />
              Investing in memcoins
            </h2>
            <div className="tags-wrapper">
              <div className="tag">High risk</div>
              <div className="tag">High profitability</div>
              <div className="tag">Memcoins</div>
              <div className="tag">Trading</div>
            </div>
            <p className="small-text caption-text">
              <span>Freq of accuruals</span> <span>Term</span>{' '}
            </p>
            <p className="medium-text caption-text bold">
              <span>Monthly</span> <span>Fixed</span>{' '}
            </p>
            <br />
            <div className="caption-text">
              <div>
                <div> APR</div>
                <div className="medium-text bold">50%-1000%</div>
              </div>
              <a href="/marketplace" className="nav-link button-light" rel="noreferrer">
                Invest
              </a>
            </div>
          </div>

          <div className="caption-block">
            <img
              src="./images/Time/caption-card-4.png"
              srcSet="./images/Time/caption-card-4-small.png 480w, ./images/Time/caption-card-4.png"
              alt="caption-4"
              className="caption-img"
            />
            <h2 className="title caption-title">
              <img src="./images/Time/logo-small.svg" alt="logo" />
              Metamask{' '}
            </h2>
            <div className="tags-wrapper">
              <div className="tag">PRE-IPO</div>
              <div className="tag">Low risk</div>
              <div className="tag">TOP</div>
              <div className="tag">Web3</div>
              <div className="tag">DeFi</div>
            </div>
            <p className="small-text caption-text">
              <span>Freq of accuruals</span> <span>Term</span>{' '}
            </p>
            <p className="medium-text caption-text bold">
              <span>Once</span> <span>Flexible</span>{' '}
            </p>
            <br />
            <div className="caption-text">
              <div>
                <div> APR</div>
                <div className="medium-text bold"> 0%-1000%</div>
              </div>
              <a href="/marketplace" className="nav-link button-light" rel="noreferrer">
                Invest
              </a>
            </div>
          </div>

          <div className="caption-block">
            <img
              src="./images/Time/caption-card-5.png"
              srcSet="./images/Time/caption-card-5-small.png 480w, ./images/Time/caption-card-5.png"
              alt="caption-5"
              className="caption-img"
            />
            <h2 className="title caption-title">
              <img src="./images/Time/logo-small.svg" alt="logo" />
              Algorithmic Trading
            </h2>
            <div className="tags-wrapper">
              <div className="tag">Medium risk</div>
              <div className="tag">Average profitability</div>
              <div className="tag">Trading</div>
            </div>
            <p className="small-text caption-text">
              <span>Freq of accuruals</span> <span>Term</span>{' '}
            </p>
            <p className="medium-text caption-text bold">
              <span>Monthly</span> <span>Fixed</span>{' '}
            </p>
            <br />
            <div className="caption-text">
              <div>
                <div> APR</div>
                <div className="medium-text bold">50%-300%</div>
              </div>
              <a href="/marketplace" className="nav-link button-light" rel="noreferrer">
                Invest
              </a>
            </div>
          </div>

          <div className="caption-block">
            <img
              src="./images/Time/caption-card-6.png"
              srcSet="./images/Time/caption-card-6-small.png 480w, ./images/Time/caption-card-6.png"
              alt="caption-6"
              className="caption-img"
            />
            <h2 className="title caption-title">
              <img src="./images/Time/logo-small.svg" alt="logo" />
              Investments in energy resources
            </h2>
            <div className="tags-wrapper">
              <div className="tag">Energy resources</div>
              <div className="tag">Low risk</div>
              <div className="tag">Freq of accruals</div>
            </div>
            <p className="small-text caption-text">
              <span>Freq of accuruals</span> <span>Term</span>{' '}
            </p>
            <p className="medium-text caption-text bold">
              <span>Monthly</span> <span>Flexible</span>{' '}
            </p>
            <br />
            <div className="caption-text">
              <div>
                <div> APR</div>
                <div className="medium-text bold">10%-70%</div>
              </div>
              <a href="/marketplace" className="nav-link button-light" rel="noreferrer">
                Invest
              </a>
            </div>
          </div>

          <div className="caption-block">
            <img
              src="./images/Time/caption-card-7.png"
              srcSet="./images/Time/caption-card-7-small.png 480w, ./images/Time/caption-card-7.png"
              alt="caption-7"
              className="caption-img"
            />
            <h2 className="title caption-title">
              <img src="./images/Time/logo-small.svg" alt="logo" />
              Liquidity loans for crypto exchanges
            </h2>
            <div className="tags-wrapper">
              <div className="tag">Medium</div>
              <div className="tag">Secure</div>
              <div className="tag">Freq of accruals</div>
            </div>
            <p className="small-text caption-text">
              <span>Freq of accuruals</span> <span>Term</span>{' '}
            </p>
            <p className="medium-text caption-text bold">
              <span>Monthly</span> <span>Flexible</span>{' '}
            </p>
            <br />
            <div className="caption-text">
              <div>
                <div> APR</div>
                <div className="medium-text bold">50%-150%</div>
              </div>
              <a href="/marketplace" className="nav-link button-light" rel="noreferrer">
                Invest
              </a>
            </div>
          </div>

          <div className="caption-block">
            <img
              src="./images/Time/caption-card-8.png"
              srcSet="./images/Time/caption-card-8-small.png 480w, ./images/Time/caption-card-8.png"
              alt="caption-8"
              className="caption-img"
            />
            <h2 className="title caption-title">
              <img src="./images/Time/logo-small.svg" alt="logo" />
              Trust management
            </h2>
            <div className="tags-wrapper">
              <div className="tag">Stable</div>
              <div className="tag">Staking</div>
              <div className="tag">USDT</div>
            </div>
            <p className="small-text caption-text">
              <span>Freq of accuruals</span> <span>Term</span>{' '}
            </p>
            <p className="medium-text caption-text bold">
              <span>Daily</span> <span>Fixed</span>{' '}
            </p>
            <br />
            <div className="caption-text">
              <div>
                <div> APR</div>
                <div className="medium-text bold">10%</div>
              </div>
              <a href="/marketplace" className="nav-link button-light" rel="noreferrer">
                Invest
              </a>
            </div>
          </div>

          <div className="caption-block">
            <img
              src="./images/Time/caption-card-9.png"
              srcSet="./images/Time/caption-card-9-small.png 480w, ./images/Time/caption-card-9.png"
              alt="caption-9"
              className="caption-img"
            />
            <h2 className="title caption-title">
              <img src="./images/Time/logo-small.svg" alt="logo" />
              Investments in PRE-IPO
            </h2>
            <div className="tags-wrapper">
              <div className="tag">TON</div>
              <div className="tag">Presale</div>
            </div>
            <p className="small-text caption-text">
              <span>Freq of accuruals</span> <span>Term</span>{' '}
            </p>
            <p className="medium-text caption-text bold">
              <span>Once</span> <span>Flexible</span>{' '}
            </p>
            <br />
            <div className="caption-text">
              <div>
                <div> APR</div>
                <div className="medium-text bold">100%-300%</div>
              </div>
              <a href="/marketplace" className="nav-link button-light" rel="noreferrer">
                Invest
              </a>
            </div>
          </div>
        </section>

        <div className="center">
          <a className="nav-link nav-link-large button-light button-large" href="/marketplace">
            <span>Start investing</span>
            <img src="./images/Time/link-arrow.svg" alt="link-arrow" />
          </a>
        </div>

        <section className="section-start">
          <div className="section-start-left">
            <h2 className="subtitle min-title">
              Start investing <br />
              <span className="text-gradient">from $100</span> now
            </h2>
            <p className="middle-text">
              One of the main advantages of the marketplace is the possibility to purchase a volume
              from a transaction starting from $100 or from a single share. This allows each user to
              invest in exclusive offers in a few clicks.
            </p>
            <a href="/register" className="nav-link button-large full-width" rel="noreferrer">
              Sign up
            </a>
          </div>
          <img
            src="./images/Time/start-phone.png"
            srcSet="./images/Time/start-phone-small.png 480w, ./images/Time/start-phone.png"
            alt="start-phone"
            className="section-start-right"
          />
        </section>

        <section className="section section-work-head">
          <img
            src="./images/Time/stone-bottom.png"
            srcSet="./images/Time/stone-bottom-small.png 768w, ./images/Time/stone-bottom.png"
            alt="stone-bottom"
            className="stone-bottom"
          />
          <h2 className="title-work extra-bold">F.A.Q.</h2>

          <div className="select-wrapper">
            <input type="checkbox" name="select" className="accordion-select" />
            <div className="section-faq-select">
              <div className="section-faq-select-title">
                What financial instruments does Time.Investments offer?
                <div className="select-arrow">
                  <img src="./images/Time/arrow-control.svg" alt="arrow" />
                </div>
              </div>
              <br />
              <div className="section-faq-content-title">
                Time.Investments offers a wide range of financial instruments including:
              </div>
              <br />
              <div className="section-faq-content-title">Pre-IPO and Angel Round</div>
              <div className="section-faq-content">
                OTC deals and venture capital investments that provide the opportunity to invest in
                early stage companies before they go public.
              </div>
              <br />
              <div className="section-faq-content-title">Commodity Fund</div>
              <div className="section-faq-content">
                Commodity group trading that allows you to participate in commodity asset
                transactions.
              </div>
              <br />
              <div className="section-faq-content-title">IPO</div>
              <div className="section-faq-content">
                Participation in the initial public offering of shares, giving the opportunity to
                purchase shares of companies at the start of their trading on the exchange.
              </div>
              <br />
              <div className="section-faq-content-title">StockMarket Fund</div>
              <div className="section-faq-content">
                Trading stocks through both up and down funds (long/short funds).
              </div>
              <br />
              <div className="section-faq-content-title">Crypto Token</div>
              <div className="section-faq-content">
                Crypto assets and crypto funds offering investments in digital currencies and
                related products.
              </div>
            </div>
          </div>

          <div className="select-wrapper">
            <input type="checkbox" name="select" className="accordion-select" />
            <div className="section-faq-select">
              <div className="section-faq-select-title">
                Rigorous Evaluation Process at Time.Investments
                <div className="select-arrow">
                  <img src="./images/Time/arrow-control.svg" alt="arrow" />
                </div>
              </div>
              <br />
              <div className="section-faq-content">
                Each potential transaction is evaluated by Time Investments analysts to determine
                its potential. This is followed by a rigorous due diligence process to ensure its
                safety. Only after these stages does TI decide to participate in a particular deal.
              </div>
            </div>
          </div>

          <div className="select-wrapper">
            <input type="checkbox" name="select" className="accordion-select" />
            <div className="section-faq-select">
              <div className="section-faq-select-title">
                How to invest on the platform?
                <div className="select-arrow">
                  <img src="./images/Time/arrow-control.svg" alt="arrow" />
                </div>
              </div>
              <br />
              <div className="section-faq-content-title">Registration on the platform</div>
              <div className="section-faq-content">
                The user registers on the website by creating an account.
              </div>
              <br />
              <div className="section-faq-content-title">Account funding</div>
              <div className="section-faq-content">
                The user funds their account on the platform through various payment methods (e.g.
                bank transfer, credit/debit cards, e-payment systems, cryptocurrency).
              </div>
              <br />
              <div className="section-faq-content-title">Deal Selection</div>
              <div className="section-faq-content">
                User goes to the Marketplace section of the website and selects a deal that
                interests him/her. - Explores all available information about the selected deal on
                its page.
              </div>
              <br />
              <div className="section-faq-content-title">Investing</div>
              <div className="section-faq-content">
                If there are sufficient funds in the account, the user enters the required amount of
                investment and clicks the “Invest” button.
              </div>
              <br />
              <div className="section-faq-content-title">Checking the deal</div>
              <div className="section-faq-content">
                The system instantly checks the deal for compliance with the specified conditions.-
                After verification, the user receives information about the deal in the Dashboard
                section.
              </div>
              <br />
              <div className="section-faq-content-title">Deal monitoring</div>
              <div className="section-faq-content">
                The user can monitor the status and details of the deal on the Dashboard page or in
                the personal cabinet on the site.
              </div>
              <br />
              <div className="section-faq-content-title">Deal Terms and Conditions</div>
              <div className="section-faq-content">
                The user can view the terms and conditions of payments, accruals and exit options on
                each specific deal page.This process is described in order to make the way of
                investing on the platform clear and transparent for users, reducing the number of
                questions and possible misunderstandings.
              </div>
            </div>
          </div>

          <div className="select-wrapper">
            <input type="checkbox" name="select" className="accordion-select" />
            <div className="section-faq-select">
              <div className="section-faq-select-title">
                How fast is the withdrawal of free funds from Time.Investments accounts to the
                client's personal account?{' '}
                <div className="select-arrow">
                  <img src="./images/Time/arrow-control.svg" alt="arrow" />
                </div>
              </div>
              <br />
              <div className="section-faq-content-title">
                There is an option to withdraw free funds from Time Investments accounts to the
                client's personal account
              </div>
              <br />
              <div className="section-faq-content-title">Cryptocurrency</div>
              <div className="section-faq-content">
                Withdrawals can be made by sending cryptocurrency. This method is usually the
                fastest and funds can be transferred within 24 hours of request, excluding weekends
                and holidays.
              </div>
              <br />
              <div className="small-text">
                * It is important to keep in mind that withdrawal times may vary depending on the
                current workload of the TI financial system, as well as specific payment processing
                conditions of banks and payment processors. It is always advisable to familiarize
                yourself with the withdrawal terms and conditions and possible transaction fees in
                advance.
              </div>
            </div>
          </div>
        </section>

        <br />
        <h2 className="title title-work center-text">Media about us</h2>

        <section className="section section-links">
          <a
            className="link-block"
            target="_blank"
            href="https://ifamagazine.com/time-investments-majority-of-financial-advisers-plan-to-increase-clients-exposure-to-aim-over-the-next-12-months/"
            rel="noreferrer"
          >
            <p className="link-text">ifamagazine</p>
            <img alt="" src="./images/Time/IFA.svg" />
          </a>
          <a
            className="link-block"
            target="_blank"
            href="https://www.investmentweek.co.uk/tag/time-investments"
            rel="noreferrer"
          >
            <p className="link-text">investmentweek</p>
            <img alt="" src="./images/Time/investment-week.svg" />
          </a>
          <a
            className="link-block"
            target="_blank"
            href="https://www.paradigm.co.uk/compliance/strategic-partners/TIME-Investments.html"
            rel="noreferrer"
          >
            <p className="link-text">paradigm</p>
            <img alt="" src="./images/Time/paradigm.svg" />
          </a>
        </section>
        <div className="section section-socials-links">
          <a
            href="https://x.com/TimeInvestcorp"
            className="socials-link"
            target="_blank"
            rel="noreferrer"
          >
            <img src="./images/Time/x-icon-large.svg" alt="x/twitter" />
            <span className="socials-link-text">X.com</span>
          </a>
          <a href="https://facebook.com" className="socials-link" target="_blank" rel="noreferrer">
            <img src="./images/Time/fb-icon-large.svg" alt="facebook" />
            <span className="socials-link-text">Facebook</span>
          </a>
          <a
            href="https://t.me/+YPAutk0YDU85MDYy"
            className="socials-link"
            target="_blank"
            rel="noreferrer"
          >
            <img src="./images/Time/tg-icon-large.svg" alt="telegram" />
            <span className="socials-link-text">Telegram</span>
          </a>
          <a href="https://linkedin.com" className="socials-link" target="_blank" rel="noreferrer">
            <img src="./images/Time/linkedin-link-large.svg" alt="linkedin-link" />
            <span className="socials-link-text">Linkedin</span>
          </a>
        </div>
      </div>
      <div className="bottom-wrapper">
        <img className="ellipse-bottom" src="./images/Time/ellipse-bottom.png" alt="" />
        <div className="footer-wrapper">
          <img
            src="./images/Time/stone-down.png"
            srcSet="./images/Time/stone-down.png"
            alt="stone-down"
            className="stone-down"
          />
          <footer className="footer">
            <div className="footer-block">
              <div className="footer-top">
                <a href="/" rel="noreferrer">
                  <img src="./images/Time/logo.svg" alt="logo" className="logo" width="85px" />
                </a>
                <p className="small-text">© 2025 Time Investments</p>
              </div>
              <div className="footer-bottom">
                <div className="large-text-work bold" style={{ marginBottom: '10px' }}>
                  Community
                </div>
                <div className="footer-social">
                  <a href="https://t.me/+YPAutk0YDU85MDYy" target="_blank" rel="noreferrer">
                    <img src="./images/Time/tg-icon.svg" alt="telegram" />
                  </a>
                  {/* <a href="https://facebook.com" target="_blank" rel="noreferrer">
                    <img src="./images/Time/fb-icon.svg" alt="facebook" />
                  </a> */}
                  <a href="https://x.com/TimeInvestcorp" target="_blank" rel="noreferrer">
                    <img src="./images/Time/x-icon.svg" alt="x/twitter" />
                  </a>
                  {/* <a href="https://linkedin.com" target="_blank" rel="noreferrer">
                    <img src="./images/Time/linkedin-link.svg" alt="linkedin" />
                  </a> */}
                </div>
              </div>
            </div>
            <div className="footer-block">
              <div className="footer-right">
                <a className="medium-text" href="/dashboard" rel="noreferrer">
                  Dashboard
                </a>
                <a className="medium-text" href="/assets" rel="noreferrer">
                  Assets
                </a>
                <a className="medium-text" href="/trade-convert" rel="noreferrer">
                  Convert
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
      <a
        className="connect"
        href="https://t.me/timeinvestments_support"
        rel="noreferrer"
        target="_blank"
      >
        <img src="./images/Time/message.svg" alt="" />
      </a>
    </StyledBg>
  )
}
